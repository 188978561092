import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`How top Facebook bot, `}<a parentName="h4" {...{
        "href": "http://www.machaao.com"
      }}>{`Machaao`}</a>{`, is using `}<a parentName="h4" {...{
        "href": "http://www.dashbot.io"
      }}>{`Dashbot`}</a>{` analytics to improve user experience and engagement.`}</h4>
    <h2>{`Cricket Is a Way of Life in India`}</h2>
    <p>{`By the age of four, every Indian boy has been introduced to cricket. It’s a legacy passed on from generation to generation for over four decades.`}</p>
    <p>{`Cricket is not only a passion for Indians, it’s one of the most popular sports in the world with an estimated 2.5 billion fanbase.`}</p>
    <p>{`The founders of `}<a parentName="p" {...{
        "href": "http://www.machaao.com/"
      }}>{`Machaao`}</a>{`, Harshal Dhir, Aditya, Chavan, and Sunil Chiluvuri, all hail from India and have taken their passion for Cricket to new heights and mediums — spreading cricket news and scores throughout the world with their Facebook Messenger bot, `}<a parentName="p" {...{
        "href": "http://www.machaao.com/"
      }}>{`Machaao`}</a>{`.`}</p>
    <p>{`Prior to launching their bot, the team was working on a contextual Messenger app that enabled users to access live scores, Twitter trends, Youtube videos, and Google images, and book tickets via group chat.`}</p>
    <p>{`During the 2015 Cricket World Cup, the team and their friends were following India on the Machaao app. When India made it to the semifinals, the stats made the team realize the power of contextual messaging, and they filed a patent for contextual messaging.`}</p>
    <p>{`Once Facebook opened up Messenger to developers, the founders decided to integrate their cricket score functionality into Messenger — and Ganglia, Machaao’s bot for sports, was born!`}</p>
    <p>{`Machaao is now one of the top bots on Facebook with 200,000+ users. They were recently accepted into Facebook’s FbStart program.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/05/07125953/0_O3Oz_4AUlFlO6x7e.png",
          "alt": null
        }}></img></figure>{`Analytics are Key — Listen to Your Users`}</h2>
    <p>{`Everyday, Harshal and Aditya each spend 30 minutes looking at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` reports.`}</p>
    <p>{`They read through the messages to check the health of their bot, and to make sure the bot is responding correctly to any messages users send in. As they point out, if the bot doesn’t respond the way users want, they will tell you!`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` provides top message reports, message funnels, and even the full transcripts to enable developers to traverse through the bot to see the messages users send in as well as the bots responses. These reports are particularly useful for improving bot response effectiveness.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/05/07125952/0_EU-5mT0R_enKwrTf.png",
          "alt": null
        }}></img></figure><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/05/07125952/0_qfrNtDca9_AGqClj.png",
          "alt": null
        }}></img></figure>{`New Features Based on Dashbot Insights`}</h2>
    <p>{`Machaao created three new features directly from the insights they learned through the Dashbot Top Messages report:`}</p>
    <h3><strong parentName="h3">{`1. Live Scores`}</strong></h3>
    <p>{`Users kept asking for live score updates. Before, users had to send in a team name to subscribe to get notifications. Now, users can click a quick reply button to get live score updates at anytime. Machaao is finding even if the users are subscribed, they still click the quick reply to get live updates.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125951/0_Nqo6HU0jOaBTJgzG-300x119.png)
    </figure>
### **2. Follow Player**
    <p>{`Machaao planned to add functionality to follow a player, but they weren’t yet sure when would be the right time to add it. Based on the messages in the Top Message Report, the feature jumped up on the priority list as users were asking for player info.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125951/0_fK4Rd8Ikhtsa1ssQ-300x286.png)
    </figure>
### **3. Mute / Pause**
    <p>{`The mute functionality allows users to pause notifications without unsubscribing from the bot. This has helped to greatly increase user retention. Previously, if a match was one-sided, and a user’s team was losing, they would get upset and unsubscribe so as not to see the score updates. This in turn cost Machaao more, as they would end up having to pay to re-acquire them through ads.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125950/0_bMkBldhvIwkwaxCf-300x182.png)
    </figure>
Each of these features has helped to increase user engagement and retention in
the bot.
    <h2>{`Creating a Personality Increases Engagement`}</h2>
    <p>{`Machaao strives to respond to any message a user may have.`}</p>
    <p>{`In addition to the features Machaao built from the insights they learned through Dashbot, the team also added additional responses to users messages to increase engagement.`}</p>
    <p>{`For example, they noticed users in Pakistan would message “Inshallah” (if God wills it) when their team is playing well. They hired someone who knew Urdu to come up with an appropriate response, “ameen summa ameen” or “so be it.”`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125949/0_CAGKZ5axJ5UPHevA-300x189.png)
    </figure>
One of the tricks when the bot doesn’t already have a response, and for some of
the more quirky messages, Machaao will respond with a giphy. This also helps
create a personality for the bot which increases engagement as well.
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/05/07125949/0_eRrlXNHSxEj3FwSP-300x262.png",
          "alt": null
        }}></img></figure>{`Acquiring Users`}</h2>
    <p>{`As with any platform and service, user acquisition is incredibly important and can be quite challenging.`}</p>
    <p>{`Machaao has found Facebook Ads for Messenger to be extremely effective. They have even seen a reduction in the user acquisition cost by 90% since they started six months ago.`}</p>
    <p>{`However, it required a lot of work to get to this point. The team worked really hard to optimize their campaigns. They are very thoughtful with how they spend their money. They prefer researching and working hard over experimenting and getting it wrong.`}</p>
    <p>{`Their hard work didn’t go unnoticed. The Facebook team was so impressed with how Machaao did their campaigns, they reached out and invited Machaao into the beta access for Ads for Messenger.`}</p>
    <p>{`Facebook has helped the team a lot with how to leverage Ad Manager to its fullest.`}</p>
    <p>{`At the same time, Machaao also provides feedback and suggestions to Facebook on additional features, based on what they’re seeing through Dashbot reporting. If they see on Dashbot that a user wants something, they relay the information to Facebook that they need it.`}</p>
    <p>{`Facebook Ads may not work the same for everyone, but it does work very well for cricket. Machaao found that if the ads enhanced content users were already consuming, they would lead to higher conversion.`}</p>
    <p>{`Machaao doesn’t make use of an intermediate, web landing page, but instead directs users directly to the bot.`}</p>
    <p>{`The key is to have a really well done bot experience — a good “bot script” as Aditya puts it, and to execute it well. Machaao reads through the messages via Dashbot reports to make sure the bot is responding well to all the messages. As Aditya mentions, just like in normal conversation, as soon as there is dead air, people lose interest — make sure the bot always responds appropriately.`}</p>
    <p>{`An additional benefit of skipping the web landing page, is that it gets the user directly into the bot, and you can re-engage them later if needed.`}</p>
    <h2>{`The Future for Machaao`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.machaao.com/"
      }}>{`Machaao`}</a>{` has lots of plans for the future for additional features and functionality as well as expanding into additional sports.`}</p>
    <p>{`They are taking a well thought out, methodical approach. They want to serve the cricket experience to the fullest, and then they will expand.`}</p>
    <p>{`Machaao recently launched cricket video clips, starting with the Pakistan Super League.`}</p>
    <p>{`Stay tuned for new features and the expansion to football (soccer)!`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we have bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      